<template>
  <div>
    <v-row cols="12" class="text-center mb-4">
      <v-col cols="12" class="text-center color-white">
        <h1 style="font-family: 'Raleway light', sans-serif">
          Plans & Pricing
        </h1>
        <br />
        <h2>Transform Your Ad Campaigns with Pivotad.ai</h2>
        <p>
          Effortless, AI-Powered Advertising at Your Fingertips <br />Choose
          Your Plan. Boost Your Growth.
        </p>

        <div>
          <v-row class="justify-center align-center ma-0">
            <span
              class="font-family-raleway-medium"
              :class="isMonthly ? 'color-white' : 'color-blue'"
              >Yearly</span
            >
            <v-switch class="ml-2" v-model="isMonthly" color="#0057FF" inset />
            <span
              class="font-family-raleway-medium"
              :class="isMonthly ? 'color-blue' : 'color-white'"
              >Monthly</span
            >
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" md="3" sm="6" xs="12" class="mt-3 px-4">
        <!--first pricing account-->
        <v-card
          id="Hover"
          class="border-16"
          style="padding: 30px"
          height="100%"
        >
          <div class="my-4 mx-0">
            <div class="d-flex align-center text-wrap">
              <span class="logo-plan">🌟</span>
              <h2 style="font-family: 'Raleway light', sans-serif">
                Starter Plan
              </h2>
            </div>
          </div>
          <div class="justify-center align-center ma-0 py-2">
            <div v-if="isMonthly">
              <strong class="family" style="font-size: 40px">$29</strong
              >/monthly
            </div>
            <span v-else
              ><strong class="family" style="font-size: 40px">$22</strong>/if
              paid yearly</span
            >
          </div>
          <div class="ma-0 px-2 pt-2 text-left">
            <div class="options-style font-family-raleway-medium text-center">
              <h4 style="color: #7a7d9c">
                <span
                  >Perfect for small businesses looking to step into AI-powered
                  advertising.</span
                >
              </h4>
            </div>
          </div>
          <v-divider style="width: 100%; margin: 40px 0 !important"></v-divider>
          <div class="ma-0 px-2 text-left">
            <div class="options-style font-family-raleway-medium">
              <h4 class="mb-4 d-flex">
                <img :src="checkIcon" class="mr-4 d-none check-icon" />
                <img
                  :src="checkIconWhite"
                  class="mr-2 check-icon-white"
                  width="24"
                  height="24"
                />
                <span>
                  Only $29/month or save with $22/month when paid annually
                </span>
              </h4>
              <h4 class="mb-4 d-flex">
                <img :src="checkIcon" class="mr-4 d-none check-icon" />
                <img
                  :src="checkIconWhite"
                  class="mr-2 check-icon-white"
                  width="24"
                  height="24"
                />
                <span>Manage One Ad Account</span>
              </h4>
              <h4 class="mb-4 d-flex">
                <img :src="checkIcon" class="mr-4 d-none check-icon" />
                <img
                  :src="checkIconWhite"
                  class="mr-2 check-icon-white"
                  width="24"
                  height="24"
                />
                <span>Create up to 3 Targeted Campaigns</span>
              </h4>
              <h4 class="mb-4 d-flex">
                <img :src="checkIcon" class="mr-4 d-none check-icon" />
                <img
                  :src="checkIconWhite"
                  class="mr-2 check-icon-white"
                  width="24"
                  height="24"
                />
                <span>Use up to 50 Keywords</span>
              </h4>
              <h4 class="mb-4 d-flex">
                <img :src="checkIcon" class="mr-4 d-none check-icon" />
                <img
                  :src="checkIconWhite"
                  class="mr-2 check-icon-white"
                  width="24"
                  height="24"
                />
                <span>Design up to 3 Engaging Responsive Search Ads</span>
              </h4>

              <!--              <h3 class="mb-4 d-flex">-->
              <!--                <img :src="checkIcon" class="mr-4 check-icon" />-->
              <!--                <img-->
              <!--                  :src="checkIconWhite"-->
              <!--                  class="mr-4 d-none check-icon-white"-->
              <!--                />-->
              <!--                <span> Up to <span class="font-none">50</span> keywords </span>-->
              <!--              </h3>-->
              <!--              <h3 class="mb-4 d-flex">-->
              <!--                <img :src="checkIcon" class="mr-4 check-icon" />-->
              <!--                <img-->
              <!--                  :src="checkIconWhite"-->
              <!--                  class="mr-4 d-none check-icon-white"-->
              <!--                />-->
              <!--                <span> Up to <span class="font-none">3</span> ads </span>-->
              <!--              </h3>-->
            </div>
          </div>
          <v-row class="justify-center align-end ma-0 mt-5">
            <v-btn
              id="signup-btn"
              class="mb-6 bg-primary text-none font-weight-bold signup-btn border-16"
              @click="registerModal = !registerModal"
              style="
                border-radius: 10px;
                font-family: 'Raleway light', sans-serif;
                box-shadow: none;
              "
              width="100%"
              x-large
              dark
            >
              Get Started
            </v-btn>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" sm="6" xs="12" class="mt-3 px-4">
        <!--first pricing account-->
        <v-card
          id="Hover"
          class="border-16"
          style="padding: 30px"
          height="100%"
        >
          <!--          <v-row class="justify-center align-center ma-0">-->
          <!--            <span-->
          <!--              class="font-family-raleway-medium"-->
          <!--              :class="ex11 ? 'color-gray' : 'color-purple'"-->
          <!--            >yearly</span-->
          <!--            >-->
          <!--            <v-switch class="ml-2" v-model="isMonthly" color="#FF5096" inset />-->
          <!--            <span-->
          <!--              class="font-family-raleway-medium"-->
          <!--              :class="ex11 ? 'color-purple' : 'color-gray'"-->
          <!--            >monthly</span-->
          <!--            >-->
          <!--          </v-row>-->
          <div class="my-4 mx-0">
            <div class="d-flex align-center text-wrap">
              <span class="logo-plan">💼</span>
              <h2 style="font-family: 'Raleway light', sans-serif">
                Professional
              </h2>
            </div>
          </div>
          <div class="justify-center align-center ma-0 py-2">
            <div v-if="isMonthly">
              <strong class="family" style="font-size: 40px">$49</strong
              >/monthly
            </div>
            <span v-else
              ><strong class="family" style="font-size: 40px">$45</strong>/if
              paid yearly</span
            >
          </div>
          <div class="ma-0 px-2 pt-2 text-left">
            <div class="options-style font-family-raleway-medium text-center">
              <h4 style="color: #7a7d9c">
                <span
                  >Ideal for businesses ready to scale up their advertising
                  reach.</span
                >
              </h4>
            </div>
          </div>
          <v-divider style="width: 100%; margin: 40px 0 !important"></v-divider>
          <div class="ma-0 px-2 text-left">
            <div class="options-style font-family-raleway-medium">
              <h4 class="mb-4 d-flex">
                <img :src="checkIcon" class="mr-4 d-none check-icon" />
                <img
                  :src="checkIconWhite"
                  class="mr-2 check-icon-white"
                  width="24"
                  height="24"
                />
                <span>
                  Just $49/month or enjoy savings at $45/month for annual
                  payment</span
                >
              </h4>
              <h4 class="mb-4 d-flex">
                <img :src="checkIcon" class="mr-4 d-none check-icon" />
                <img
                  :src="checkIconWhite"
                  class="mr-2 check-icon-white"
                  width="24"
                  height="24"
                />
                <span>Handle up to 5 Ad Accounts</span>
              </h4>
              <h4 class="mb-4 d-flex">
                <img :src="checkIcon" class="mr-4 d-none check-icon" />
                <img
                  :src="checkIconWhite"
                  class="mr-2 check-icon-white"
                  width="24"
                  height="24"
                />
                <span>Launch up to 15 Dynamic Campaigns</span>
              </h4>
              <h4 class="mb-4 d-flex">
                <img :src="checkIcon" class="mr-4 d-none check-icon" />
                <img
                  :src="checkIconWhite"
                  class="mr-2 check-icon-white"
                  width="24"
                  height="24"
                />
                <span>Explore up to 500 Keywords</span>
              </h4>
              <h4 class="mb-4 d-flex">
                <img :src="checkIcon" class="mr-4 d-none check-icon" />
                <img
                  :src="checkIconWhite"
                  class="mr-2 check-icon-white"
                  width="24"
                  height="24"
                />
                <span>Craft up to 15 Creative Responsive Search Ads</span>
              </h4>

              <!--              <h3 class="mb-4 d-flex">-->
              <!--                <img :src="checkIcon" class="mr-4 check-icon" />-->
              <!--                <img-->
              <!--                  :src="checkIconWhite"-->
              <!--                  class="mr-4 d-none check-icon-white"-->
              <!--                />-->
              <!--                <span> Up to <span class="font-none">50</span> keywords </span>-->
              <!--              </h3>-->
              <!--              <h3 class="mb-4 d-flex">-->
              <!--                <img :src="checkIcon" class="mr-4 check-icon" />-->
              <!--                <img-->
              <!--                  :src="checkIconWhite"-->
              <!--                  class="mr-4 d-none check-icon-white"-->
              <!--                />-->
              <!--                <span> Up to <span class="font-none">3</span> ads </span>-->
              <!--              </h3>-->
            </div>
          </div>
          <v-row class="justify-center align-end ma-0 mt-5">
            <v-btn
              id="signup-btn"
              class="mb-6 bg-primary text-none font-weight-bold signup-btn border-16"
              @click="registerModal = !registerModal"
              style="
                border-radius: 10px;
                font-family: 'Raleway light', sans-serif;
                box-shadow: none;
              "
              width="100%"
              x-large
              dark
            >
              Get Started
            </v-btn>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" sm="6" xs="12" class="mt-3 px-4">
        <!--first pricing account-->
        <v-card
          id="Hover"
          class="border-16"
          style="padding: 30px"
          height="100%"
        >
          <!--          <v-row class="justify-center align-center ma-0">-->
          <!--            <span-->
          <!--              class="font-family-raleway-medium"-->
          <!--              :class="ex11 ? 'color-gray' : 'color-purple'"-->
          <!--            >yearly</span-->
          <!--            >-->
          <!--            <v-switch class="ml-2" v-model="isMonthly" color="#FF5096" inset />-->
          <!--            <span-->
          <!--              class="font-family-raleway-medium"-->
          <!--              :class="ex11 ? 'color-purple' : 'color-gray'"-->
          <!--            >monthly</span-->
          <!--            >-->
          <!--          </v-row>-->
          <div class="my-4 mx-0">
            <div class="d-flex align-center text-wrap">
              <span class="logo-plan">🏆</span>
              <h2 style="font-family: 'Raleway light', sans-serif">
                Enterprise
              </h2>
            </div>
          </div>
          <div class="justify-center align-center ma-0 py-2">
            <div v-if="isMonthly">
              <strong class="family" style="font-size: 40px">$199</strong
              >/monthly
            </div>
            <span v-else
              ><strong class="family" style="font-size: 40px">$180</strong>/if
              paid yearly</span
            >
          </div>
          <div class="ma-0 px-2 pt-2 text-left">
            <div class="options-style font-family-raleway-medium text-center">
              <h4 style="color: #7a7d9c">
                <span
                  >The ultimate advertising solution for limitless
                  possibilities.</span
                >
              </h4>
            </div>
          </div>
          <v-divider style="width: 100%; margin: 40px 0 !important"></v-divider>
          <div class="ma-0 px-2 text-left">
            <div class="options-style font-family-raleway-medium">
              <h4 class="mb-4 d-flex">
                <img :src="checkIcon" class="mr-4 d-none check-icon" />
                <img
                  :src="checkIconWhite"
                  class="mr-2 check-icon-white"
                  width="24"
                  height="24"
                />
                <span>
                  $199/month or get the best value at $180/month when paid
                  annually</span
                >
              </h4>
              <h4 class="mb-4 d-flex">
                <img :src="checkIcon" class="mr-4 d-none check-icon" />
                <img
                  :src="checkIconWhite"
                  class="mr-2 check-icon-white"
                  width="24"
                  height="24"
                />
                <span>Enjoy Unlimited Ad Accounts</span>
              </h4>
              <h4 class="mb-4 d-flex">
                <img :src="checkIcon" class="mr-4 d-none check-icon" />
                <img
                  :src="checkIconWhite"
                  class="mr-2 check-icon-white"
                  width="24"
                  height="24"
                />
                <span>Create Unlimited Campaigns</span>
              </h4>
              <h4 class="mb-4 d-flex">
                <img :src="checkIcon" class="mr-4 d-none check-icon" />
                <img
                  :src="checkIconWhite"
                  class="mr-2 check-icon-white"
                  width="24"
                  height="24"
                />
                <span>Utilize Unlimited Keywords</span>
              </h4>
              <h4 class="mb-4 d-flex">
                <img :src="checkIcon" class="mr-4 d-none check-icon" />
                <img
                  :src="checkIconWhite"
                  class="mr-2 check-icon-white"
                  width="24"
                  height="24"
                />
                <span>Develop Unlimited Responsive Search Ads</span>
              </h4>

              <!--              <h3 class="mb-4 d-flex">-->
              <!--                <img :src="checkIcon" class="mr-4 check-icon" />-->
              <!--                <img-->
              <!--                  :src="checkIconWhite"-->
              <!--                  class="mr-4 d-none check-icon-white"-->
              <!--                />-->
              <!--                <span> Up to <span class="font-none">50</span> keywords </span>-->
              <!--              </h3>-->
              <!--              <h3 class="mb-4 d-flex">-->
              <!--                <img :src="checkIcon" class="mr-4 check-icon" />-->
              <!--                <img-->
              <!--                  :src="checkIconWhite"-->
              <!--                  class="mr-4 d-none check-icon-white"-->
              <!--                />-->
              <!--                <span> Up to <span class="font-none">3</span> ads </span>-->
              <!--              </h3>-->
            </div>
          </div>
          <v-row class="justify-center align-end ma-0 mt-5">
            <v-btn
              id="signup-btn"
              class="mb-6 bg-primary text-none font-weight-bold signup-btn border-16"
              @click="registerModal = !registerModal"
              style="
                border-radius: 10px;
                font-family: 'Raleway light', sans-serif;
                box-shadow: none;
              "
              width="100%"
              x-large
              dark
            >
              Get Started
            </v-btn>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" sm="6" xs="12" class="mt-3 px-4">
        <!--first pricing account-->
        <v-card
          id="Hover"
          class="border-16"
          style="padding: 30px"
          height="100%"
        >
          <!--          <v-row class="justify-center align-center ma-0">-->
          <!--            <span-->
          <!--              class="font-family-raleway-medium"-->
          <!--              :class="ex11 ? 'color-gray' : 'color-purple'"-->
          <!--            >yearly</span-->
          <!--            >-->
          <!--            <v-switch class="ml-2" v-model="isMonthly" color="#FF5096" inset />-->
          <!--            <span-->
          <!--              class="font-family-raleway-medium"-->
          <!--              :class="ex11 ? 'color-purple' : 'color-gray'"-->
          <!--            >monthly</span-->
          <!--            >-->
          <!--          </v-row>-->
          <div class="my-4 mx-0">
            <div class="d-flex align-center text-wrap">
              <span class="logo-plan">💡</span>
              <h2 style="font-family: 'Raleway light', sans-serif">
                Agency Serv.
              </h2>
            </div>
          </div>
          <div class="justify-center align-center ma-0 py-2">
            <div v-if="isMonthly">
              <strong class="family" style="font-size: 40px">$999</strong
              >/monthly
            </div>
            <span v-else
              ><strong class="family" style="font-size: 40px">$800</strong>/if
              paid yearly</span
            >
          </div>
          <div class="ma-0 px-2 pt-2 text-left">
            <div class="options-style font-family-raleway-medium text-center">
              <h4 style="color: #7a7d9c">
                <span
                  >Optimal for companies managing Google Ads in-house or through
                  agencies.</span
                >
              </h4>
            </div>
          </div>
          <v-divider style="width: 100%; margin: 40px 0 !important"></v-divider>
          <div class="ma-0 px-2 text-left">
            <div class="options-style font-family-raleway-medium">
              <h4 class="mb-4 d-flex">
                <img :src="checkIcon" class="mr-4 d-none check-icon" />
                <img
                  :src="checkIconWhite"
                  class="mr-2 check-icon-white"
                  width="24"
                  height="24"
                />
                <span>
                  $999/month or get the best value at $800/month when paid
                  annually</span
                >
              </h4>
              <h4 class="mb-4 d-flex">
                <img :src="checkIcon" class="mr-4 d-none check-icon" />
                <img
                  :src="checkIconWhite"
                  class="mr-2 check-icon-white"
                  width="24"
                  height="24"
                />
                <span>1 Dedicated account manager </span>
              </h4>
              <h4 class="mb-4 d-flex">
                <img :src="checkIcon" class="mr-4 d-none check-icon" />
                <img
                  :src="checkIconWhite"
                  class="mr-2 check-icon-white"
                  width="24"
                  height="24"
                />
                <span>1 Dedicated account strategist </span>
              </h4>
              <h4 class="mb-4 d-flex">
                <img :src="checkIcon" class="mr-4 d-none check-icon" />
                <img
                  :src="checkIconWhite"
                  class="mr-2 check-icon-white"
                  width="24"
                  height="24"
                />
                <span>Keyword & Competitors research: unlimited keywords</span>
              </h4>
              <h4 class="mb-4 d-flex">
                <img :src="checkIcon" class="mr-4 d-none check-icon" />
                <img
                  :src="checkIconWhite"
                  class="mr-2 check-icon-white"
                  width="24"
                  height="24"
                />
                <span>1:1 Weekly meetings</span>
              </h4>

              <!--              <h3 class="mb-4 d-flex">-->
              <!--                <img :src="checkIcon" class="mr-4 check-icon" />-->
              <!--                <img-->
              <!--                  :src="checkIconWhite"-->
              <!--                  class="mr-4 d-none check-icon-white"-->
              <!--                />-->
              <!--                <span> Up to <span class="font-none">50</span> keywords </span>-->
              <!--              </h3>-->
              <!--              <h3 class="mb-4 d-flex">-->
              <!--                <img :src="checkIcon" class="mr-4 check-icon" />-->
              <!--                <img-->
              <!--                  :src="checkIconWhite"-->
              <!--                  class="mr-4 d-none check-icon-white"-->
              <!--                />-->
              <!--                <span> Up to <span class="font-none">3</span> ads </span>-->
              <!--              </h3>-->
            </div>
          </div>
          <v-row class="justify-center align-end ma-0 mt-5">
            <v-btn
              id="signup-btn"
              class="mb-6 bg-primary text-none font-weight-bold signup-btn border-16"
              @click="$router.push('/contact-us')"
              style="
                border-radius: 10px;
                font-family: 'Raleway light', sans-serif;
                box-shadow: none;
              "
              width="100%"
              x-large
              dark
            >
              Contact Us
            </v-btn>
          </v-row>
        </v-card>
      </v-col>
      <!--      <v-col cols="12" md="3" sm="12" class="mt-3 mx-auto px-4">-->
      <!--        <v-card-->
      <!--          id="Hover"-->
      <!--          class="border-16"-->
      <!--          style="padding: 40px"-->
      <!--          height="100%;border: 2px solid black "-->
      <!--        >-->
      <!--          <v-row class="justify-center align-center ma-0">-->
      <!--            <span-->
      <!--              class="font-family-raleway-medium"-->
      <!--              :class="ex12 ? 'color-gray' : 'color-purple'"-->
      <!--              >yearly</span-->
      <!--            >-->
      <!--            <v-switch class="ml-2" v-model="ex12" color="#FF5096" inset />-->
      <!--            <span-->
      <!--              class="font-family-raleway-medium"-->
      <!--              :class="ex12 ? 'color-purple' : 'color-gray'"-->
      <!--              >monthly</span-->
      <!--            >-->
      <!--          </v-row>-->
      <!--          <v-row class="justify-center align-center ma-0 py-0">-->
      <!--            <div v-if="ex12">-->
      <!--              <strong class="family" style="font-size: 48px">$49</strong-->
      <!--              >/monthly-->
      <!--            </div>-->
      <!--            <span v-else-->
      <!--              ><strong class="family" style="font-size: 48px">$45</strong>/if-->
      <!--              paid yearly</span-->
      <!--            >-->
      <!--          </v-row>-->
      <!--          <v-row class="justify-center ma-0 py-0">-->
      <!--            <h2 style="font-family: 'Raleway light', sans-serif" class="mt-1">-->
      <!--              Small business-->
      <!--            </h2>-->
      <!--            <v-chip-->
      <!--              style="-->
      <!--                background-color: #f5f0ea !important;-->
      <!--                color: #f2994a;-->
      <!--                height: 22px;-->
      <!--              "-->
      <!--              class="font-weight-bold text-uppercase mb-5 mt-2"-->
      <!--            >-->
      <!--              <p class="my-3 mx-5 font-family-raleway-bold">Recommended</p>-->
      <!--            </v-chip>-->
      <!--          </v-row>-->
      <!--          <v-divider-->
      <!--            style="-->
      <!--              width: 120px;-->
      <!--              margin-left: 30% !important;-->
      <!--              margin-bottom: 20px;-->
      <!--            "-->
      <!--          ></v-divider>-->

      <!--          <v-row class="px-2 ma-0 text-left">-->
      <!--            <div class="options-style font-family-raleway-medium">-->
      <!--              <h3 class="mb-4 d-flex">-->
      <!--                <img :src="checkIcon" class="mr-4 check-icon" />-->
      <!--                <img-->
      <!--                  :src="checkIconWhite"-->
      <!--                  class="mr-4 d-none check-icon-white"-->
      <!--                />-->
      <!--                <span>-->
      <!--                  Up to <span class="font-none">5</span> ad accounts-->
      <!--                </span>-->
      <!--              </h3>-->
      <!--              <h3 class="mb-4 d-flex">-->
      <!--                <img :src="checkIcon" class="mr-4 check-icon" />-->
      <!--                <img-->
      <!--                  :src="checkIconWhite"-->
      <!--                  class="mr-4 d-none check-icon-white"-->
      <!--                />-->
      <!--                <span> Up to <span class="font-none">500</span> keywords </span>-->
      <!--              </h3>-->
      <!--              <h3 class="mb-4 d-flex">-->
      <!--                <img :src="checkIcon" class="mr-4 check-icon" />-->
      <!--                <img-->
      <!--                  :src="checkIconWhite"-->
      <!--                  class="mr-4 d-none check-icon-white"-->
      <!--                />-->
      <!--                <span> Up to <span class="font-none">10</span> ads </span>-->
      <!--              </h3>-->
      <!--            </div>-->
      <!--          </v-row>-->
      <!--          <v-row class="justify-center ma-0 mt-5">-->
      <!--            <v-btn-->
      <!--              id="signup-btn"-->
      <!--              class="mb-6 bg-primary text-none font-weight-bold signup-btn border-16"-->
      <!--              style="-->
      <!--                border-radius: 10px;-->
      <!--                font-family: 'Raleway light', sans-serif;-->
      <!--                box-shadow: none;-->
      <!--              "-->
      <!--              width="100%"-->
      <!--              x-large-->
      <!--              dark-->
      <!--            >-->
      <!--              Sign up now!-->
      <!--            </v-btn>-->
      <!--          </v-row>-->
      <!--        </v-card>-->
      <!--      </v-col>-->

      <!--      <v-col cols="12" md="3" sm="12" class="mt-3 mx-auto px-4">-->
      <!--        <v-card-->
      <!--          id="Hover"-->
      <!--          class="border-16"-->
      <!--          style="padding: 40px"-->
      <!--          height="100%"-->
      <!--        >-->
      <!--          <v-row class="justify-center align-center ma-0">-->
      <!--            <span-->
      <!--              class="font-family-raleway-medium"-->
      <!--              :class="ex13 ? 'color-gray' : 'color-purple'"-->
      <!--              >yearly</span-->
      <!--            >-->
      <!--            <v-switch class="ml-2" v-model="ex13" color="#FF5096" inset />-->
      <!--            <span-->
      <!--              class="font-family-raleway-medium"-->
      <!--              :class="ex13 ? 'color-purple' : 'color-gray'"-->
      <!--              >monthly</span-->
      <!--            >-->
      <!--          </v-row>-->
      <!--          <v-row class="justify-center align-center ma-0 py-0">-->
      <!--            <div v-if="ex13">-->
      <!--              <strong class="family" style="font-size: 48px">$199</strong-->
      <!--              >/monthly-->
      <!--            </div>-->
      <!--            <span v-else-->
      <!--              ><strong class="family" style="font-size: 48px">$180</strong>/if-->
      <!--              paid yearly</span-->
      <!--            >-->
      <!--          </v-row>-->
      <!--          <v-row class="justify-center my-4 ma-0">-->
      <!--            <v-col>-->
      <!--              <h2 style="font-family: 'Raleway light', sans-serif">-->
      <!--                Enterprise-->
      <!--              </h2>-->
      <!--            </v-col>-->
      <!--          </v-row>-->
      <!--          <v-divider-->
      <!--            style="-->
      <!--              width: 120px;-->
      <!--              margin-left: 30% !important;-->
      <!--              margin-bottom: 20px;-->
      <!--            "-->
      <!--          ></v-divider>-->

      <!--          <v-row class="px-2 text-left ma-0">-->
      <!--            <div class="options-style font-family-raleway-medium">-->
      <!--              <h3 class="mb-4 d-flex">-->
      <!--                <img :src="checkIcon" class="mr-4 check-icon" />-->
      <!--                <img-->
      <!--                  :src="checkIconWhite"-->
      <!--                  class="mr-4 d-none check-icon-white"-->
      <!--                />-->
      <!--                <span> Unlimited ad accounts </span>-->
      <!--              </h3>-->
      <!--              <h3 class="mb-4 d-flex">-->
      <!--                <img :src="checkIcon" class="mr-4 check-icon" />-->
      <!--                <img-->
      <!--                  :src="checkIconWhite"-->
      <!--                  class="mr-4 d-none check-icon-white"-->
      <!--                />-->
      <!--                <span> Unlimited keywords </span>-->
      <!--              </h3>-->
      <!--              <h3 class="mb-4 d-flex">-->
      <!--                <img :src="checkIcon" class="mr-4 check-icon" />-->
      <!--                <img-->
      <!--                  :src="checkIconWhite"-->
      <!--                  class="mr-4 d-none check-icon-white"-->
      <!--                />-->
      <!--                <span> Unlimited ads </span>-->
      <!--              </h3>-->
      <!--            </div>-->
      <!--          </v-row>-->

      <!--          <div class="justify-center ma-0 mt-5">-->
      <!--            <v-btn-->
      <!--              id="signup-btn"-->
      <!--              class="mb-6 bg-primary text-none font-weight-bold border-16"-->
      <!--              style="-->
      <!--                border-radius: 10px;-->
      <!--                font-family: 'Raleway light', sans-serif;-->
      <!--                box-shadow: none;-->
      <!--              "-->
      <!--              width="100%"-->
      <!--              x-large-->
      <!--              dark-->
      <!--            >-->
      <!--              Sign up now!-->
      <!--            </v-btn>-->
      <!--          </div>-->
      <!--        </v-card>-->
      <!--      </v-col>-->

      <!--      <v-col cols="12" md="3" sm="12" class="mt-3 mx-auto px-4">-->
      <!--        <v-card-->
      <!--          id="Hover"-->
      <!--          class="border-16"-->
      <!--          style="padding: 40px"-->
      <!--          height="100%"-->
      <!--        >-->
      <!--          <v-row class="justify-center align-center ma-0">-->
      <!--            <span-->
      <!--              class="font-family-raleway-medium"-->
      <!--              :class="ex13 ? 'color-gray' : 'color-purple'"-->
      <!--            >yearly</span-->
      <!--            >-->
      <!--            <v-switch class="ml-2" v-model="ex13" color="#FF5096" inset />-->
      <!--            <span-->
      <!--              class="font-family-raleway-medium"-->
      <!--              :class="ex13 ? 'color-purple' : 'color-gray'"-->
      <!--            >monthly</span-->
      <!--            >-->
      <!--          </v-row>-->
      <!--          <v-row class="justify-center align-center ma-0 py-0">-->
      <!--            <div v-if="ex13">-->
      <!--              <strong class="family" style="font-size: 48px">$199</strong-->
      <!--              >/monthly-->
      <!--            </div>-->
      <!--            <span v-else-->
      <!--            ><strong class="family" style="font-size: 48px">$180</strong>/if-->
      <!--              paid yearly</span-->
      <!--            >-->
      <!--          </v-row>-->
      <!--          <v-row class="justify-center my-4 ma-0">-->
      <!--            <v-col>-->
      <!--              <h2 style="font-family: 'Raleway light', sans-serif">-->
      <!--                Enterprise-->
      <!--              </h2>-->
      <!--            </v-col>-->
      <!--          </v-row>-->
      <!--          <v-divider-->
      <!--            style="-->
      <!--              width: 120px;-->
      <!--              margin-left: 30% !important;-->
      <!--              margin-bottom: 20px;-->
      <!--            "-->
      <!--          ></v-divider>-->

      <!--          <v-row class="px-2 text-left ma-0">-->
      <!--            <div class="options-style font-family-raleway-medium">-->
      <!--              <h3 class="mb-4 d-flex">-->
      <!--                <img :src="checkIcon" class="mr-4 check-icon" />-->
      <!--                <img-->
      <!--                  :src="checkIconWhite"-->
      <!--                  class="mr-4 d-none check-icon-white"-->
      <!--                />-->
      <!--                <span> Unlimited ad accounts </span>-->
      <!--              </h3>-->
      <!--              <h3 class="mb-4 d-flex">-->
      <!--                <img :src="checkIcon" class="mr-4 check-icon" />-->
      <!--                <img-->
      <!--                  :src="checkIconWhite"-->
      <!--                  class="mr-4 d-none check-icon-white"-->
      <!--                />-->
      <!--                <span> Unlimited keywords </span>-->
      <!--              </h3>-->
      <!--              <h3 class="mb-4 d-flex">-->
      <!--                <img :src="checkIcon" class="mr-4 check-icon" />-->
      <!--                <img-->
      <!--                  :src="checkIconWhite"-->
      <!--                  class="mr-4 d-none check-icon-white"-->
      <!--                />-->
      <!--                <span> Unlimited ads </span>-->
      <!--              </h3>-->
      <!--            </div>-->
      <!--          </v-row>-->

      <!--          <div class="justify-center ma-0 mt-5">-->
      <!--            <v-btn-->
      <!--              id="signup-btn"-->
      <!--              class="mb-6 bg-primary text-none font-weight-bold border-16"-->
      <!--              style="-->
      <!--                border-radius: 10px;-->
      <!--                font-family: 'Raleway light', sans-serif;-->
      <!--                box-shadow: none;-->
      <!--              "-->
      <!--              width="100%"-->
      <!--              x-large-->
      <!--              dark-->
      <!--            >-->
      <!--              Sign up now!-->
      <!--            </v-btn>-->
      <!--          </div>-->
      <!--        </v-card>-->
      <!--      </v-col>-->
      <Login
        :dialog="loginModal"
        @closeModal="loginModal = false"
        @openSignUp="registerModal = true"
      />
      <Registration
        :dialog="registerModal"
        @closeModal="registerModal = false"
        @openSignIn="loginModal = true"
      />
    </v-row>
  </div>
</template>
<script>
import checkIcon from "../../assets/icon/check-icon-blue.svg";
import checkIconWhite from "../../assets/icon/check-icon-white.svg";

export default {
  components: {
    Registration: () => import("@/components/MainLanding/Modals/Registration"),
    Login: () => import("@/components/MainLanding/Modals/Login"),
  },
  name: "Pricing",
  data() {
    return {
      checkIcon,
      checkIconWhite,
      isMonthly: true,
      loginModal: false,
      registerModal: false,
      ex11: false,
      ex12: false,
      ex13: false,
      ex14: false,
    };
  },
  watch: {
    ex11(val) {
      if (val) {
        this.ex12 = false;
        this.ex13 = false;
        this.ex14 = false;
      }
    },
    ex12(val) {
      if (val) {
        this.ex11 = false;
        this.ex13 = false;
        this.ex14 = false;
      }
    },
    ex13(val) {
      if (val) {
        this.ex11 = false;
        this.ex12 = false;
        this.ex14 = false;
      }
    },
    ex14(val) {
      if (val) {
        this.ex11 = false;
        this.ex12 = false;
        this.ex13 = false;
      }
    },
  },
};
</script>
<style lang="scss">
.check-icon-white {
  padding: 2px;
  background-color: #0057ff;
  border-radius: 8px;
}
.logo-plan {
  display: flex;
  justify-content: center;
  font-size: 30px;
  width: 64px;
  height: 64px;
  background-color: #e1e4ff;
  border-radius: 17px;
  margin-right: 20px;
  padding: 11px 14px;
}
#Hover {
  display: flex;
  flex-direction: column;
}
//#Hover:hover {
//  background: linear-gradient(
//      rgba(0, 87, 255, 0.85) 1.27%,
//      rgba(0, 87, 255, 0.85) 102.94%
//    ),
//    url("../../assets/img/photo.png") no-repeat fixed center !important;
//  color: white;
//}
//#Hover:hover .check-icon {
//  display: none !important;
//}
//
//#Hover:hover .check-icon {
//  display: block !important;
//}
//
//#Hover:hover #signup-btn {
//  background-color: #ffffff !important;
//  color: #0057ff !important;
//}
.family {
  font-size: 48px;
}
h2 {
  font-family: "Raleway light", sans-serif;
}
</style>
